import React from 'react';
import BannerHeader1 from "../assets/ads/banner-header.png";
import BannerHeader2 from "../assets/ads/banner-header-2.png";

const BannerAds = () => {
    return (
        <>
            <div className="pt-5 grid grid-cols-2 gap-4">
                <div>
                    <img src={BannerHeader1} alt="Banner Header 1" className="w-full" />
                </div>
                <div>
                    <img src={BannerHeader2} alt="Banner Header 2" className="w-full" />
                </div>
            </div>
        </>
    );
};

export default BannerAds;