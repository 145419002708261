import React, { useState } from "react";
import CoinView from "../features/coins/CoinView";
import BannerAds from "../components/BannerAds";
import BannerHeaderDetail from "../assets/ads/banner-header-3.png";
import BannerSidebarRight from "../assets/ads/banner-sidebar-right.png";
import BannerSidebarLeft from "../assets/ads/banner-sidebar-left.png";

const Home = () => {
  const [showLeftBanner, setShowLeftBanner] = useState(true);
  const [showRightBanner, setShowRightBanner] = useState(true);
  const [showBottomBanner, setShowBottomBanner] = useState(true);

  return (
    <div className="container">
      {/* Left Sticky Banner */}
      {showLeftBanner && (
        <div className="fixed left-0 top-1/2 transform -translate-y-1/2 hidden lg:block">
          <button
            className="absolute -right-8 top-0 w-6 h-6 text-lg bg-red-600 text-white rounded-full shadow-md hover:bg-red-700 transition"
            onClick={() => setShowLeftBanner(false)}
          >
            ✕
          </button>
          <img src={BannerSidebarLeft} alt="Left Banner" className="w-48" />
        </div>
      )}

      <BannerAds />
      <CoinView />

      {/* Right Sticky Banner */}
      {showRightBanner && (
        <div className="fixed right-0 top-1/2 transform -translate-y-1/2 hidden lg:block">
          <button
            className="absolute -left-8 top-0 w-6 h-6 text-lg bg-red-600 text-white rounded-full shadow-md hover:bg-red-700 transition"
            onClick={() => setShowRightBanner(false)}
          >
            ✕
          </button>
          <img src={BannerSidebarRight} alt="Right Banner" className="w-48" />
        </div>
      )}

      {/* Bottom Sticky Banner */}
      {showBottomBanner ? (
        <div className="fixed bottom-0 left-0 w-full bg-white shadow-md text-center py-2 hidden lg:block">
          <button
            className="absolute right-2 top-2 w-6 h-6 text-lg bg-red-600 text-white rounded-full shadow-md hover:bg-red-700 transition"
            onClick={() => setShowBottomBanner(false)}
          >
            ✕
          </button>
          <img src={BannerHeaderDetail} alt="Bottom Banner" className="mx-auto" />
        </div>
      ) : (
        <button
          className="fixed bottom-4 right-4 bg-red-600 text-white px-3 py-2 rounded-full shadow-md hover:bg-red-700 transition"
          onClick={() => setShowBottomBanner(true)}
        >
          Hiện lại banner
        </button>
      )}
    </div>
  );
};

export default Home;
