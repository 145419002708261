import React from 'react';
import Trending from '../components/Trending';
const TrendingCoins = () => {
  return (
    <div>
      <div className="container">
        <Trending />
      </div>
    </div>
  );
};

export default TrendingCoins;
